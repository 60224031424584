import Rails from "@rails/ujs";

document.addEventListener("turbolinks:load", () => {
  const dataExporters = document.querySelectorAll("[data-report-exporter]");
  dataExporters.forEach((exporter) => {
    exporter.addEventListener("click", (e) => {
      e.preventDefault();
      const form = exporter.closest("form");

      Rails.ajax({
        url: exporter.getAttribute("href"),
        type: "get",
        data: new URLSearchParams(new FormData(form)).toString(),
        success: function (data) {
          const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
          const download = URL.createObjectURL(blob);

          // Create a link to download it
          const downloader = document.createElement("a");
          downloader.href = download;
          downloader.setAttribute(
            "download",
            `farm-stock-export_${new Date()
              .toLocaleDateString("en-US")
              .replaceAll("/", "-")}.csv`
          );
          downloader.click();
        },
        error: function (data) {
          //console.log(data);
        },
      });
    });
  });
});
