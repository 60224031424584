import Rails from "@rails/ujs";
import Leaflet from "leaflet";

document.addEventListener("turbolinks:load", () => {
  const TRANSITION_DURATION = 300;
  const MAP_CENTER_COORDS = [56.1304, -106.3468];
  const MAP_DEFAULT_ZOOM = 3;

  const fileUploadFields = document.querySelectorAll("[data-file-field]");
  const dragAndDropField = document.querySelectorAll(
    "[data-drag-and-drop-file]"
  );
  const dangerousMethods = document.querySelectorAll(
    "[data-confirm-dangerous]"
  );

  const geolocationFields = document.querySelectorAll(
    "[data-geo-location-field]"
  );

  const tagCloudFields = document.querySelectorAll(
    "[data-tag-cloud-container]"
  );

  const selectAllTriggers = document.querySelectorAll(
    "[data-select-all-trigger]"
  );

  fileUploadFields.forEach((uploadField) => {
    if (uploadField.files.length > 0) {
      handleFileField(uploadField);
    }
    uploadField.addEventListener("change", (e) => {
      if (e.target.files.length > 0) {
        handleFileField(e.target);
      }
    });
  });

  dragAndDropField.forEach((dAndDField) => {
    //console.log(dAndDField);
    const fileField = dAndDField.querySelector("[data-file-field]");
    const errorContainer = dAndDField.querySelector(
      "[data-file-upload-error-container]"
    );
    const errorMessages = errorContainer.querySelector(
      "[data-file-upload-error-messages]"
    );
    dAndDField.addEventListener("dragover", (e) => {
      e.preventDefault();
    });
    dAndDField.addEventListener("drop", (e) => {
      e.preventDefault();
      const validTransfer = new DataTransfer();
      if (
        document.querySelector("[data-upload-file-multiple-upload-container]")
      ) {
        const { files } = fileField;
        files.forEach((file) => validTransfer.add(file));
      }
      const uploadErrors = [];
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        const file = e.dataTransfer.files[i];
        if (fileField.accept.includes(file.type)) {
          validTransfer.items.add(file);
        } else {
          uploadErrors.push({
            file: file.name,
            error: `Unsupported file type`,
          });
        }
      }
      if (validTransfer.files.length > 0) {
        fileField.files = validTransfer.files;
        fileField.dispatchEvent(new Event("change"));
      }
      if (uploadErrors.length > 0) {
        errorContainer.classList.toggle("hidden");
        errorMessages.innerHTML = uploadErrors.map(
          (error) => `${error.file} - ${error.error}`
        );
      } else {
        if (!errorContainer.classList.contains("hidden")) {
          errorContainer.classList.toggle("hidden");
        }
        errorMessages.innerHTML = "";
      }
    });
  });

  function handleFileField(uploadElement) {
    const previewWrapper = document.querySelector(
      `[data-upload-file-preview-${uploadElement.id}]`
    );
    const directionField = document.querySelector(
      `[data-file-direction-field-${uploadElement.id}]`
    );
    const fileNameField = document.querySelector(
      `[data-file-name-${uploadElement.id}]`
    );
    const errorContainer = document.querySelector(
      "[data-file-upload-error-container]"
    );
    const bulkUploadContainer = document.querySelector(
      `[data-upload-file-multiple-upload-container]`
    );
    const uploadTemplate = document.querySelector("#file-upload-template");
    if (errorContainer) {
      const errorMessages = errorContainer.querySelector(
        "[data-file-upload-error-messages]"
      );
      if (!errorContainer.classList.contains("hidden")) {
        errorContainer.classList.toggle("hidden");
      }
      errorMessages.innerHTML = "";
    }
    if (bulkUploadContainer && uploadTemplate) {
      //console.log("THIS HAS THE BULK");
      [...uploadElement.files].forEach((file, index) => {
        //console.log(file);
        const templateNode = uploadTemplate.content.cloneNode(true);
        const image = templateNode.querySelector("img");
        image.src = URL.createObjectURL(file);
        const fileName = templateNode.querySelector("[data-file-name]");
        const fileSize = templateNode.querySelector("[data-file-size]");
        if (fileName) {
          fileName.innerText = file.name;
        }
        if (fileSize) {
          fileSize.innerText = `${(file.size * 0.000001).toFixed(2)} mb`;
        }
        //console.log(uploadElement)
        const button = templateNode.querySelector("[data-remove-upload]");
        button.setAttribute("data-uploaded-file", file.name);
        button.addEventListener("click", function () {
          const dt = new DataTransfer();
          const { files } = uploadElement;
          //console.log(files)
          for (let i = 0; i < files.length; i++) {
            const cFile = files[i];
            if (cFile.name !== file.name) dt.items.add(cFile); // here you exclude the file. thus removing it.
          }
          uploadElement.files = dt.files; // Assign the updates list
          removeFormUpload(button);
        });
        bulkUploadContainer.appendChild(templateNode);
      });
    } else {
      const file = uploadElement.files[0];
      if (previewWrapper) {
        const imageClassList = ["h-full", "w-full"];
        if (previewWrapper.dataset.containImage) {
          imageClassList.push("object-contain");
        } else {
          imageClassList.push("object-cover");
        }

        const image = document.createElement("img");
        image.src = URL.createObjectURL(file);
        image.classList.add(...imageClassList);
        previewWrapper.innerHTML = "";
        previewWrapper.appendChild(image);
      }
      if (fileNameField) {
        fileNameField.innerHTML = file.name;
      }
      if (directionField) {
        directionField.innerHTML = "Change File";
      }
    }
  }

  function removeFormUpload(trigger) {
    const container = document.querySelector(
      `[data-upload-file-multiple-upload-container]`
    );
    const wrapper = trigger.closest("[data-upload-wrapper]");
    if (wrapper) {
      wrapper.remove();
      //console.log(container.childNodes.length);
      if (!container.querySelectorAll("[data-upload-wrapper]").length) {
        //console.log("FIRED");
        container.innerHTML = "";
      }
    }
  }

  dangerousMethods.forEach((dangerousMethod) => {
    const methodForm = dangerousMethod.closest("form");
    const warning = methodForm.querySelector("[data-dangerous-method-modal]");
    const overlay = warning.querySelector("[data-modal-overlay]");
    const modal = warning.querySelector("[data-modal]");
    const cancel = warning.querySelector("[data-cancel]");
    const confirm = warning.querySelector("[data-confirm]");

    const closeWarning = () => {
      overlay.classList.remove("opacity-100");
      overlay.classList.add("opacity-0");
      modal.classList.add(
        "opacity-0",
        "translate-y-4",
        "sm:translate-y-0",
        "sm:scale-95"
      );
      modal.classList.remove("opacity-100", "translate-y-0", "sm:scale-100");
      setTimeout(() => {
        warning.classList.remove("z-40");
        warning.classList.add("-z-10");
      }, TRANSITION_DURATION);
    };

    dangerousMethod.addEventListener("click", (e) => {
      e.preventDefault();
      warning.classList.remove("-z-10");
      warning.classList.add("z-40");
      overlay.classList.remove("opacity-0");
      overlay.classList.add("opacity-100");
      modal.classList.remove(
        "opacity-0",
        "translate-y-4",
        "sm:translate-y-0",
        "sm:scale-95"
      );
      modal.classList.add("opacity-100", "translate-y-0", "sm:scale-100");
    });
    overlay.addEventListener("click", () => {
      closeWarning();
    });
    cancel.addEventListener("click", () => {
      closeWarning();
    });
    confirm.addEventListener("click", () => {
      Rails.fire(methodForm, "submit");
    });
  });

  geolocationFields.forEach((geoField) => {
    const mapContainer = geoField.querySelector("[data-map-container]");
    if (mapContainer) {
      const markers = [];
      const map = Leaflet.map(mapContainer, {
        doubleClickZoom: false,
        scrollWheelZoom: false,
      });
      let defaultCoords = MAP_CENTER_COORDS;

      const latField = geoField.querySelector("[data-latitude-field]");
      const longField = geoField.querySelector("[data-longitude-field]");
      const hasLatLong = latField.value && longField.value;
      if (hasLatLong) {
        defaultCoords = [latField.value, longField.value];
      }

      map.setView(defaultCoords, MAP_DEFAULT_ZOOM);

      Leaflet.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 19,
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(map);

      const updateMarkers = (latLong) => {
        if (markers.length > 0) {
          markers.forEach((marker) => marker.remove());
        }
        const newMarker = Leaflet.marker(latLong).addTo(map);
        markers.push(newMarker);
        latField.value = latLong[0];
        longField.value = latLong[1];
        map.setView(latLong);
      };
      if (hasLatLong) {
        updateMarkers(defaultCoords);
      }
      map.on("dblclick", (e) => {
        updateMarkers([e.latlng.lat, e.latlng.lng]);
      });
    }
  });

  tagCloudFields.forEach((tagCloud) => {
    const toggle = tagCloud.querySelector("[data-cloud-toggle]");
    const cloudContainers = tagCloud.querySelectorAll("[data-tag-cloud]");

    toggle.addEventListener("click", (e) => {
      const toggleButton = toggle.querySelector("button");
      const toggleSpan = toggle.querySelector("span");
      if (toggle.dataset.closed === "true") {
        replaceClass(toggleButton, "bg-gray-200", "bg-zinc-600");
        replaceClass(toggleSpan, "translate-x-0", "translate-x-5");
        cloudContainers.forEach((container) => {
          resizeExpandingSection(container, true);
        });
        toggle.setAttribute("data-closed", "false");
      } else {
        replaceClass(toggleButton, "bg-zinc-600", "bg-gray-200");
        replaceClass(toggleSpan, "translate-x-5", "translate-x-0");
        cloudContainers.forEach((container) => {
          resizeExpandingSection(container, false);
        });
        toggle.setAttribute("data-closed", "true");
      }
    });

    window.addEventListener("resize", () => {
      cloudContainers.forEach((container) => {
        if (toggle.dataset.closed !== "true") {
          resizeExpandingSection(container, true);
        }
      });
    });

    cloudContainers.forEach((container) => {
      const tagContainer = container.querySelector("[data-tag-container]");
      const clearButton = container.querySelector("[data-clear-all]");
      const addTagField = container.querySelector("[data-tag-to-add]");
      const addTagButton = container.querySelector("[data-add-tag]");
      const inputs = tagContainer.querySelectorAll("input");

      const checkedElements = container.querySelectorAll("input:checked");
      if (checkedElements?.length > 0) {
        if (toggle.getAttribute("data-closed") == "true") {
          toggle.dispatchEvent(new Event("click"));
        }
      }
      inputs.forEach((input) => {
        input.addEventListener("change", (e) => {
          if (input.getAttribute("checked") === "checked") {
            input.removeAttribute("checked");
          } else {
            input.setAttribute("checked", "checked");
          }
        });
      });
      clearButton.addEventListener("click", () => {
        const checkboxes = container.querySelectorAll('input[type="checkbox"]');
        checkboxes.forEach((checkbox) => (checkbox.checked = false));
      });
      addTagButton.addEventListener("click", () => {
        if (addTagField.value) {
          const newTag = createTag(
            addTagField.value,
            addTagButton.dataset.field
          );
          tagContainer.appendChild(newTag);
          addTagField.value = "";
          resizeExpandingSection(container, true);
        }
      });
    });
  });

  function resizeExpandingSection(expander, open) {
    if (open) {
      expander.style.maxHeight = expander.scrollHeight + "px";
    } else {
      expander.style.maxHeight = 0;
    }
  }

  function replaceClass(element, toRemove, toAdd) {
    element.classList.remove(toRemove);
    element.classList.add(toAdd);
  }

  function createTag(tagName, fieldName) {
    const cleanedTag = tagName.replaceAll(/\s/gm, "-").toLowerCase();
    const labelClasses = "block";
    const label = document.createElement("label");
    label.classList.add(...labelClasses.split(" "));
    label.for = cleanedTag;

    const inputClasses = "sr-only peer";
    const input = document.createElement("input");
    input.classList.add(...inputClasses.split(" "));
    input.name = fieldName + "[]";
    input.type = "checkbox";
    input.value = tagName;
    input.id = cleanedTag;
    input.checked = true;

    const spanClasses =
      "select-none inline-flex peer-checked:bg-red-400 peer-checked:hover:bg-red-200 items-center px-3 py-1 rounded-full text-sm font-medium bg-gray-100 text-gray-800 hover:bg-gray-200 cursor-pointer";
    const span = document.createElement("span");
    span.classList.add(...spanClasses.split(" "));
    span.innerHTML = tagName;

    label.appendChild(input);
    label.appendChild(span);

    return label;
  }

  selectAllTriggers.forEach((trigger) => {
    const wrapper = trigger.closest("[data-select-all-wrapper]");
    if (wrapper) {
      trigger.addEventListener("click", () => {
        const checkboxes = wrapper.querySelectorAll('input[type="checkbox"]');
        checkboxes.forEach((checkbox) => {
          checkbox.click();
        });
      });
    }
  });
});
