document.addEventListener("turbolinks:load", () => {
  const uploadApprovals = document.querySelectorAll("[data-upload-approval]");

  uploadApprovals.forEach((upload) => {
    const inactiveUploadClasses = [
      "block",
      "hover:bg-gray-50",
      "cursor-pointer",
      "border-gray-100",
    ];
    const activeUploadClasses = ["border-gray-400"];
    upload.addEventListener("click", () => {
      if (upload.dataset.isActive === "true") return;

      uploadApprovals.forEach((uploadApproval) => {
        if (uploadApproval !== upload) {
          uploadApproval.classList.remove(...activeUploadClasses);
          uploadApproval.classList.add(...inactiveUploadClasses);
          uploadApproval.setAttribute("data-is-active", false);
        } else {
          uploadApproval.classList.add(...activeUploadClasses);
          uploadApproval.classList.remove(...inactiveUploadClasses);
          uploadApproval.setAttribute("data-is-active", true);
        }
      });
      const details = document.querySelectorAll(
        "[data-upload-approval-details]"
      );
      details.forEach((detail) => {
        if (
          detail.dataset.uploadApprovalDetails === upload.dataset.uploadApproval
        ) {
          if (detail.classList.contains("hidden")) {
            detail.classList.remove("hidden");
          }
        } else {
          if (!detail.classList.contains("hidden")) {
            detail.classList.add("hidden");
          }
        }
      });
    });
  });
});
