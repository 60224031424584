document.addEventListener("turbolinks:load", () => {
  const NO_ITEMS_MESSAGE =
    "At least one User or Upload must be selected to sync.";

  const forms = document.querySelectorAll("[data-shopify-sync-form]");

  forms.forEach((form) => {
    const errorMessageContainer = form.querySelector("[data-form-errors]");
    const itemsToSync = form.querySelectorAll("[data-synced-items]");
    const itemToggles = form.querySelectorAll("input[data-item-to-sync]");
    const submitButton = form.querySelector("[data-submitter]");
    itemToggles.forEach((toggle) => {
      updateCount(toggle);
      toggle.addEventListener("click", () => updateCount(toggle));
    });

    function updateCount(toggleElm) {
      const counter = form.querySelector(
        `[data-synced-items="${toggleElm.dataset.itemToSync}"]`
      );
      let newCount = 0;
      if (toggleElm.checked) {
        newCount = parseInt(counter.dataset.itemCount) + 1;
      } else {
        newCount = Math.max(parseInt(counter.dataset.itemCount) - 1, 0);
      }
      counter.setAttribute("data-item-count", newCount);
      counter.innerText = newCount;
    }

    submitButton.addEventListener("click", (e) => {
      if (!canSubmitForm()) {
        e.preventDefault();
      }
    });

    form.addEventListener("submit", (e) => {
      if (!canSubmitForm()) {
        e.preventDefault();
      }
    });

    function canSubmitForm() {
      if (errorMessageContainer.innerText.length > 0) {
        errorMessageContainer.classList.add("hidden");
        errorMessageContainer.innerText = "";
      }
      if (!canSubmit(itemsToSync)) {
        errorMessageContainer.classList.remove("hidden");
        errorMessageContainer.innerText = NO_ITEMS_MESSAGE;
        return false;
      }
      return true;
    }

    function canSubmit(itemsToSync) {
      let hasItems = false;
      itemsToSync.forEach((item) => {
        //console.log(parseInt(item.dataset.itemCount));
        if (parseInt(item.dataset.itemCount) > 0) {
          hasItems = true;
        }
      });
      return hasItems;
    }
  });
});
