import Leaflet from "leaflet";
document.addEventListener("turbolinks:load", () => {
  const GEOLOCATION_TAB = "details";

  const accordionContainers = document.querySelectorAll(
    "[data-accordion-container]"
  );

  const timeStamps = document.querySelectorAll("[data-localize-time]");

  const tabbedData = document.querySelectorAll("[data-tabbed-data]");

  const maps = document.querySelectorAll("[data-map-display]");

  accordionContainers.forEach((container) => {
    const trigger = container.querySelector("[data-accordion-trigger]");
    const content = container.querySelector("[data-accordion-content]");
    const openIcon = container.querySelector("[data-accordion-opener]");
    const closeIcon = container.querySelector("[data-accordion-closer]");

    const toggleIconDisplays = () => {
      if (closeIcon) {
        closeIcon.classList.toggle("block");
        closeIcon.classList.toggle("hidden");
      }
      if (openIcon) {
        openIcon.classList.toggle("hidden");
        openIcon.classList.toggle("block");
      }
    };
    trigger.addEventListener("click", () => {
      content.classList.toggle("mt-6");
      if (trigger.dataset.open == "true") {
        content.style.maxHeight = null;
        trigger.setAttribute("data-open", "false");
        toggleIconDisplays();
      } else {
        content.style.maxHeight = content.scrollHeight + "px";
        trigger.setAttribute("data-open", "true");
        toggleIconDisplays();
      }
    });
  });

  timeStamps.forEach((time) => {
    localizeTime(time);
  });

  tabbedData.forEach((dataSet) => {
    const activeTriggerClasses = ["border-b-0", "bg-white", "text-black"];
    const nonActiveTriggerClasses = [
      "bg-gray-50",
      "text-gray-500",
      "hover:text-gray-700",
      "hover:border-gray-300",
    ];
    const triggerTags = dataSet.querySelectorAll("a[data-tab-trigger]");
    const triggerSelects = dataSet.querySelectorAll("select[data-tab-trigger]");
    const tabPages = dataSet.querySelectorAll("[data-tab]");

    const updateTabs = (tabFor) => {
      tabPages.forEach((tab) => {
        if (tab.hasAttribute(`data-tab--${tabFor}`)) {
          tab.classList.remove("hidden");
        } else if (!tab.classList.contains("hidden")) {
          tab.classList.add("hidden");
        }
      });
      triggerTags.forEach((trigger) => {
        if (trigger.dataset.tabFor === tabFor) {
          trigger.classList.add(...activeTriggerClasses);
          trigger.classList.remove(...nonActiveTriggerClasses);
          const searchParams = new URLSearchParams(window.location.search);
          searchParams.set("page", tabFor);
          const pagePath =
            window.location.origin +
            window.location.pathname +
            "?" +
            searchParams.toString();
          history.replaceState(history.state, "", pagePath);
          document.dispatchEvent(
            new CustomEvent("tab-changed", { detail: { tab: tabFor } })
          );
        } else {
          trigger.classList.remove(...activeTriggerClasses);
          trigger.classList.add(...nonActiveTriggerClasses);
        }
      });
    };

    const windowParams = new URLSearchParams(window.location.search);
    if (windowParams.has("page")) {
      updateTabs(windowParams.get("page"));
      document
        .querySelector(`[data-tab-for="${windowParams.get("page")}"`)
        .setAttribute("selected", "");
    }

    triggerSelects.forEach((select) => {
      select.addEventListener("change", (e) => {
        const newValue = e.target.value;
        const option = select.querySelector(`[value="${newValue}"]`);
        updateTabs(option.dataset.tabFor);
      });
    });

    triggerTags.forEach((tag) => {
      tag.addEventListener("click", (e) => {
        e.preventDefault();
        updateTabs(tag.dataset.tabFor);
      });
    });
  });

  maps.forEach((map) => {
    let leafletMap = initializeMap(map);
    document.addEventListener("tab-changed", (e) => {
      if (e.detail.tab === GEOLOCATION_TAB) {
        leafletMap.remove();
        leafletMap = initializeMap(map);
      }
    });
  });

  document.body.addEventListener("ajax:success", () => {
    const timeStamps = document.querySelectorAll("[data-localize-time]");
    timeStamps.forEach((time) => {
      localizeTime(time);
    });
  });
});

function initializeMap(map) {
  const leafletMap = Leaflet.map(map, {
    scrollWheelZoom: false,
  });

  let defaultCoords = [map.dataset.lat, map.dataset.long];

  leafletMap.setView(defaultCoords, map.dataset.zoom);

  Leaflet.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
    maxZoom: 19,
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  }).addTo(leafletMap);

  Leaflet.marker(defaultCoords).addTo(leafletMap);
  return leafletMap;
}

function localizeTime(timeElm) {
  const tsString = timeElm.getAttribute("datetime");
  if (tsString) {
    timeElm.innerText = new Date(tsString)
      .toLocaleString([], {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "2-digit",
      })
      .replace(/-/g, "/")
      .toUpperCase();
  }
}
