import Rails from "@rails/ujs";

document.addEventListener("turbolinks:load", () => {
  const KEYUP_DEBOUNCE = 300;

  const sortContainers = document.querySelectorAll("[data-sort-container]");
  const dataFilters = document.querySelectorAll("[data-filter-field]");
  const orderWrappers = document.querySelectorAll("[data-sort-wrapper]");

  const baseParams = new URLSearchParams(window.location.search);

  sortContainers.forEach((container) => {
    const openers = container.querySelectorAll("[data-sort-opener]");
    const sortFields = container.querySelectorAll("[data-sort-field]");
    // Only going to expect one
    const options = container.querySelector("[data-sort-options]");
    const indicator = container.querySelector("[data-sort-state-indicator]");
    openers.forEach((opener) => {
      opener.addEventListener("click", (e) => {
        e.preventDefault();
        if (container !== document.activeElement) {
          container.focus();
          container.dispatchEvent(new CustomEvent("toggle-sort-options"));
        }
      });
    });

    container.addEventListener("blur", (e) => {
      const originalTarget = e.originalTarget;
      const relatedTarget = e.relatedTarget;
      if (originalTarget.contains(relatedTarget)) {
      } else {
        if (options.dataset.open == "true") {
          container.dispatchEvent(new CustomEvent("toggle-sort-options"));
        }
      }
    });
    container.addEventListener("toggle-sort-options", () => {
      if (options) {
        if (indicator) {
          indicator.classList.toggle("rotate-180");
        }
        if (options.dataset.open == "true") {
          options.classList.remove("opacity-100", 'scale-100"');
          options.classList.add("opacity-0", "scale-95");
          options.setAttribute("data-open", "false");
        } else {
          options.classList.remove("opacity-0", 'scale-95"');
          options.classList.add("opacity-100", "scale-100");
          options.setAttribute("data-open", "true");
        }
        options.classList.toggle("hidden");
      }
    });

    sortFields.forEach((field, index) => {
      const fieldLabel = field.closest("[data-sort-field-label]");
      const toggleFieldLabel = () => {
        if (fieldLabel) {
          fieldLabel.classList.toggle("text-gray-800");
          fieldLabel.classList.toggle("font-semibold");
        }
      };
      if (
        baseParams.has(field.name) &&
        field.value == baseParams.get(field.name)
      ) {
        toggleFieldLabel();
        field.setAttribute("data-is-active", "true");
      } else if (!baseParams.has(field.name) && index === 0) {
        toggleFieldLabel();
        field.setAttribute("data-is-active", "true");
      }
      field.addEventListener("click", (e) => {
        sortFields.forEach((sortField) => {
          if (sortField != e.target) {
            sortField.setAttribute("data-is-active", "false");
            const sortFieldLabel = sortField.closest("[data-sort-field-label]");
            if (sortFieldLabel) {
              sortFieldLabel.classList.remove("font-semibold", "text-gray-800");
            }
          }
        });
        if (field.dataset.isActive === "false") {
          field.setAttribute("data-is-active", "true");
          toggleFieldLabel();
          handleOptionalFormField(field, e.target);
        }
        container.focus();
      });
    });

    orderWrappers.forEach((wrapper) => {
      const fields = wrapper.querySelectorAll("[data-order-field]");
      fields.forEach((field) => {
        field.addEventListener("click", (e) => {
          fields.forEach((field) => {
            if (field != e.target) {
              const label = field.closest("[data-sort-label]");
              if (label) {
                label.setAttribute("for", field.id);
                const indicator = label.querySelector("[data-sort-indicator]");
                if (indicator) {
                  indicator.classList.toggle("rotate-180");
                }
              }
            }
          });
          handleOptionalFormField(field, e.target);
        });
      });
    });
  });

  dataFilters.forEach((filter) => {
    const filterInputs = filter.querySelectorAll(
      'input[data-filter-input]:not([type="submit"])'
    );
    filterInputs.forEach((input) => {
      if (baseParams.has(input.name)) {
        if (input.name.includes("[]")) {
          // This is for checkbox values
          const values = baseParams.getAll(input.name);
          values.forEach((value) => {
            document
              .querySelectorAll(`[name="${input.name}"][value="${value}]`)
              .forEach((element) => (element.checked = true));
          });
        } else {
          input.value = baseParams.get(input.name);
        }
      }
      differentiallyApplyListeners(input, filter);
    });
  });

  function handleOptionalFormField(field, target) {
    const form = field.closest("[data-filter-field]");
    if (form) {
      updateQueryParams(new URLSearchParams(new FormData(form)), target.name);
      Rails.fire(form, "submit");
    } else {
      const formData = new FormData();
      formData.append(target.name, target.value);
      updateQueryParams(new URLSearchParams(formData), target.name);
      Rails.fire(field, "submit");
    }
  }

  function differentiallyApplyListeners(input, formElement) {
    const eventFunction = (e) => {
      const name = e.target.name;
      const value = e.target.value;
      updateMatchingElements(name, value, e.target);
      updateQueryParams(new URLSearchParams(new FormData(formElement)), name);
      Rails.fire(formElement, "submit");
    };
    let event = "change";
    let appliedFunction = eventFunction;
    switch (input.type) {
      case "text":
        event = "keyup";
        appliedFunction = debounce(eventFunction, KEYUP_DEBOUNCE);
        break;
      default:
        break;
    }
    input.addEventListener(event, appliedFunction);
  }

  function updateMatchingElements(name, value, target) {
    const matchingElements = document.querySelectorAll(`[name="${name}"]`);
    matchingElements.forEach((element) => {
      if (element !== target) {
        const type = target.type;
        switch (type) {
          case "checkbox":
            if (element.value === value) element.checked = target.checked;
            break;
          default:
            element.value = value;
        }
      }
    });
  }

  function updateQueryParams(params, fieldValue) {
    const pathName = window.location.pathname;
    const windowSearchParams = new URLSearchParams(window.location.search);
    windowSearchParams.forEach((value, key) => {
      if (params.has(key)) {
        while (windowSearchParams.has(key)) windowSearchParams.delete(key);
      }
    });
    let fieldPresent = false;
    params.forEach(function (value, key) {
      if (key === fieldValue && value) fieldPresent = true;
      const existingValues = windowSearchParams.getAll(key);
      if (
        value &&
        (existingValues.length === 0 || !existingValues.includes(value))
      ) {
        windowSearchParams.append(key, value);
      }
    });
    if (!fieldPresent) {
      windowSearchParams.delete(fieldValue);
    }
    const paramString = windowSearchParams.toString();
    const newPath = paramString ? [pathName, paramString].join("?") : pathName;
    history.replaceState(history.state, "", newPath);
  }

  function debounce(func, wait, immediate) {
    let timeout;
    return function () {
      const context = this,
        args = arguments;
      const later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }
});
